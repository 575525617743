import React, { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Score from '../../Tools/Score';
import { SoundBackgroundContext } from '../sound/sound-background';

const GameContainer = (
    {
        Game,
        gameId,
        introImage,
        introVideo,
        introVideoWidth = 600,
        introVideoHeight = 400,
        introTexte,
        introBouton,
        finVictoireVideo,
        finVictoireVideoWidth = 900,
        finVictoireVideoHeight = 600,
        finVictoireTexte,
        finDefaiteVideo,
        finDefaiteVideoWidth = 900,
        finDefaiteVideoHeight = 600,
        finDefaiteTexte,
        finDefaite2Video,
        finDefaite2VideoWidth = 900,
        finDefaite2VideoHeight = 600,
        finDefaite2Texte,
        finBouton,
        nextStep
    }) => {

    const { paused, play } = useContext(SoundBackgroundContext)

    const [infos, setInfos] = useState({ step: 1 });
    const gameContainer = useRef(null);

    const delayVideo = (e) => {
        setTimeout(() => {
            e.target.pause()
            setTimeout(() => e.target.play(), 500);
        }, 5);
    }

    const startVideo = (e) => {
        let promise = e.target.play();
        if (promise !== undefined) {
            promise
                .then(() => delayVideo(e))
                .catch(() => {
                    e.target.muted = true;
                    promise = e.target.play();
                    if (promise !== undefined) {
                        promise
                            .then(() => delayVideo(e))
                            .catch(() => { })
                    }
                })
        }
    }

    return (
        <div ref={gameContainer} className='gameContainer'>
            {
                infos.step === 1 &&
                <div className='gameContainer_intro'>
                    <div className='gameContainer_intro_jeu'>Jeu {gameId}/<span>5</span></div>
                    {
                        introImage && <div className='gameContainer_intro_image'><img src={introImage} alt='' /></div>
                    }
                    {
                        introVideo && <div className='gameContainer_intro_image'><video width={introVideoWidth} height={introVideoHeight} onLoadedMetadata={e => startVideo(e)} muted={paused} playsInline={true} src={introVideo} /></div>
                    }
                    <div className='gameContainer_intro_texte' dangerouslySetInnerHTML={{ __html: introTexte }}></div>
                    <div className='gameContainer_intro_link'>
                        <button
                            className='bouton'
                            onClick={() => {
                                play("click");
                                setInfos({ step: 2 });
                            }}>
                            {introBouton}
                        </button>
                    </div>
                </div>
            }
            {
                infos.step === 2 &&
                <div className='gameContainer_game'>
                    <Game
                        onFinish={(success, points) => {
                            Score.update(Game.name, points);
                            setInfos({ step: 3, success });
                        }}
                    />
                </div>
            }
            {
                infos.step === 3 &&
                <div className='gameContainer_finBox'>
                    <div className='gameContainer_fin'>
                        {
                            infos.success === 1 ?
                                (
                                    <>
                                        <div className='gameContainer_fin_image'><video width={finVictoireVideoWidth} onLoadedMetadata={e => startVideo(e)} height={finVictoireVideoHeight} autoPlay={true} muted={paused} playsInline={true} src={finVictoireVideo} /></div>
                                        <div className='gameContainer_fin_texte'>{finVictoireTexte}</div>
                                    </>
                                ) : infos.success === -1 ? (
                                    <>
                                        <div className='gameContainer_fin_image'><video width={finDefaiteVideoWidth} onLoadedMetadata={e => startVideo(e)} height={finDefaiteVideoHeight} autoPlay={true} muted={paused} playsInline={true} src={finDefaiteVideo} /></div>
                                        <div className='gameContainer_fin_texte'>{finDefaiteTexte}</div>
                                    </>
                                ) : (
                                    <>
                                        <div className='gameContainer_fin_image'><video width={finDefaite2VideoWidth} onLoadedMetadata={e => startVideo(e)} height={finDefaite2VideoHeight} autoPlay={true} muted={paused} playsInline={true} src={finDefaite2Video} /></div>
                                        <div className='gameContainer_fin_texte'>{finDefaite2Texte}</div>
                                    </>
                                )
                        }

                        <div className='gameContainer_fin_link'><Link className='bouton bouton--reverse bouton--fin' to={nextStep} onClick={() => play("click")}>{finBouton}</Link></div>
                    </div>
                </div>
            }
        </div>
    )
};

export default GameContainer;
import React, { useContext, useRef } from 'react';
import { useState, useEffect } from 'react';
import { SoundBackgroundContext } from '../../Components/sound/sound-background';
import Timer from '../../Components/timer/timer';

const GameHeure = ({ onFinish }) => {

    const DURATION = 20;
    const timerRef = useRef();

    const finishGame = () => {
        setTimeout(() => {
            setCounter(0);
            let ratioScore = timerRef.current.getRatioScore();
            let points = resultCounter.filter(result => result).length;
            let success = points === 3;
            points = points / 3 * 50 * ratioScore;

            onFinish(success ? 1 : -1, points)
        }, 1500);
    }

    const { play, stop } = useContext(SoundBackgroundContext)

    const [timing, setTiming] = useState(0);
    const increment = useRef({ vitesse: 0.04, increment: 1.004, needAcceleration: true });
    const cursorPosition = useRef(null);

    const [rotateMinutes, setRotateMinutes] = useState(0);
    const [rotateHours, setRotateHours] = useState(0);
    const [onRun, setOnRun] = useState(true)
    const [isNight, setIsNight] = useState(false)
    const [rotation, setRotation] = useState(0)

    const [counter, setCounter] = useState(0)
    const [resultCounter, setResultCounter] = useState([])

    useEffect(() => {
        const interval = setInterval(() => {
            if (onRun) {
                let tempDay = timing % 24 / 24 * 360;
                let tempHours = timing % 12 / 12 * 360;
                let tempMinutes = timing % 2 / 2 * 360;

                setRotateMinutes(tempMinutes)
                setRotateHours(tempHours - 140)
                setRotation(tempDay)

                increment.current.vitesse = (increment.current.needAcceleration ? increment.current.vitesse * increment.current.increment : increment.current.vitesse);
                setTiming(timing + increment.current.vitesse);

                if (tempDay >= 180) {
                    setIsNight(false)
                } else {
                    setIsNight(true)
                }
            }
        }, 16);
        return () => clearInterval(interval);
    }, [onRun, timing]);

    const stopAcceleration = () => {
        increment.current.needAcceleration = false;
    }
    const handleClick = () => {
        play("heure_ding")
        setCounter(counter + 1)
        document.querySelector('.gameHeure__cursor').classList.add('gameHeure__cursor-onClick')

        if (timing % 24 >= 0 && timing % 24 <= 8) {
            setResultCounter([...resultCounter, true])
            document.querySelector('.popUp__container').classList.add('success')
            setTimeout(() => {
                document.querySelector('.popUp__container') && document.querySelector('.popUp__container').classList.remove('success')
            }, 500);

        } else {
            document.querySelector('.popUp__container').classList.add('fail')
            setTimeout(() => {
                document.querySelector('.popUp__container') && document.querySelector('.popUp__container').classList.remove('fail')
            }, 500);
            setResultCounter([...resultCounter, false])
        }

        setTimeout(() => {
            document.querySelector('.gameHeure__cursor') && document.querySelector('.gameHeure__cursor').classList.remove('gameHeure__cursor-onClick')
        }, 500);
    }

    useEffect(() => {
        if (counter === 3) {
            finishGame()
        }
    }, [counter, resultCounter])

    useEffect(() => {

        let interval_monstre = null;
        let timer_monstre = null;
        timer_monstre = setTimeout(() => {
            play("heure_water1");
            interval_monstre = setInterval(() => play("heure_water1"), 7200);
        }, 2750)

        let interval_ssmarin = null;
        let timer_ssmarin = null;
        timer_ssmarin = setTimeout(() => {
            play("heure_water2");
            interval_ssmarin = setInterval(() => play("heure_water2"), 5000);
        }, 2750)
        
        play("heure_horloge");

        return () => {
            clearInterval(interval_monstre);
            clearTimeout(timer_monstre)
            clearInterval(interval_ssmarin);
            clearTimeout(timer_ssmarin)
            stop()
        }
    }, []);

    const onMouseMove = (e) => {
        const x = e.clientX
        const y = e.clientY

        cursorPosition.current.style.display = "block"
        cursorPosition.current.style.left = `${x}px`;
        cursorPosition.current.style.top = `${y}px`;
    }

    return (
        <div className="game gameHeure" onMouseMove={(e) => onMouseMove(e)} >
            <Timer ref={timerRef} duration={DURATION} message={"Vous avez 3 essais pour cliquer durant la période creuse en vert !"} onFinish={() => finishGame()} onStart={() => stopAcceleration()} />
            <div ref={cursorPosition} className='gameHeure__cursor' />
            <div className={`gameHeure__container ${isNight ? 'night' : 'day'}`} onClick={() => handleClick()}>
                <img className='gameHeure__container-sun' src={require('./assets/jour/sun.png')} style={{ transform: `rotate(${rotation}deg) translate(275%)` }} alt='sun' />
                <img className='gameHeure__container-moon' src={require('./assets/nuit/moon.png')} style={{ transform: `rotate(${rotation}deg) translate(-270%)` }} alt='moon' />
                <div className={`gameHeure__container-bushLeft ${isNight ? 'night' : 'day'}`}>
                    <div className='gameHeure__container-eyes1' />
                    <div className='gameHeure__container-eyes2' />
                    <div className='gameHeure__container-eyes3' />
                </div>
                <div className={`gameHeure__container-bushRight ${isNight ? 'night' : 'day'}`}>
                    <div className='gameHeure__container-eyes4' />
                    <div className='gameHeure__container-eyes5' />
                    <div className='gameHeure__container-eyes6' />
                    <div className='gameHeure__container-eyes7' />
                </div>
                <div className={`gameHeure__container-lake ${isNight ? 'night' : 'day'}`}>
                    <div className={`gameHeure__container-lake--night`}></div>
                    <div className={`gameHeure__container-lake--day`}></div>
                    <div className='gameHeure__container-monster' />
                    <div className='gameHeure__container-submarine' />
                </div>
                <div className={`gameHeure__container-tree ${isNight ? 'night' : 'day'}`}>
                    <div className={`gameHeure__container-tree--night`}></div>
                    <div className={`gameHeure__container-tree--day`}></div>
                </div>
                <img className='gameHeure__container-rope' src={require('./assets/rope.png')} alt='rope' />
                <div className={`gameHeure__container-landLeft ${isNight ? 'night' : 'day'}`}>
                    <div className={`gameHeure__container-landLeft--night`}></div>
                    <div className={`gameHeure__container-landLeft--day`}></div>
                </div>
                <div className={`gameHeure__container-landRight ${isNight ? 'night' : 'day'}`}>
                    <div className={`gameHeure__container-landRight--night`}></div>
                    <div className={`gameHeure__container-landRight--day`}></div>
                </div>
                <div className={`gameHeure__container-texture ${isNight ? 'night' : 'day'}`} />
                <div className='gameHeure__container-horloge'>
                    <div className={`horloge__cadran ${isNight ? 'night' : 'day'}`} />
                    <span className='horloge__hours' style={{ transform: `rotate(${rotateHours}deg)` }} />
                    <span className='horloge__minutes' style={{ transform: `rotate(${rotateMinutes}deg)`, opacity: `${onRun ? 1 - increment.current.vitesse * 7 : 1}`, filter: `blur(${onRun ? increment.current.vitesse * 10 : 0}px)` }} />
                    <div className='gameHeure__container-popUp'>
                        <div className='popUp__container' />
                    </div>
                </div>
                <div className='gameHeure__container-counter'>
                    <span className={`counter__first ${resultCounter[0] === true ? 'success' : resultCounter[0] === false ? 'fail' : ''}`} />
                    <span className={`counter__second ${resultCounter[1] === true ? 'success' : resultCounter[1] === false ? 'fail' : ''}`} />
                    <span className={`counter__third ${resultCounter[2] === true ? 'success' : resultCounter[2] === false ? 'fail' : ''}`} />
                </div>

            </div>
        </div>
    )
};

export default GameHeure;
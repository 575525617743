import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import Timer from "../../Components/timer/timer";
import { Canvas, useFrame } from "@react-three/fiber";
import Walls from "./Walls";
import Email from "./Email";
import { SoundBackgroundContext } from "../../Components/sound/sound-background";

const GameEmail = ({ onFinish }) => {

    const DURATION = 30;
    const timerRef = useRef();

    const finishGame = () => {
        let success = nbSpam.current === score ? 1 : -1;
        let points = 200 * score / nbSpam.current;
        points = points < 0 ? 0 : points > 180 ? 200 : points;
        onFinish(success, points);
    };

    const { play, stop } = useContext(SoundBackgroundContext)

    const [emails, setEmails] = useState([]);
    const [score, setScore] = useState(0);
    const finger = useRef(null)
    const nbSpam = useRef(0);

    const createEmail = () => {
        const x = Math.floor(Math.random() * 12) - 6;
        const y = Math.floor(Math.random() * 6) - 3;

        const uniqueId = Math.floor(Math.random() * 100000000);
        const typeRandom = Math.floor(Math.random() * 3);
        let type = "incognito";

        if (typeRandom === 0) {
            type = "spam";
        } else if (typeRandom === 1) {
            type = "email";
        }

        if (type === "spam" || type === "incognito") {
            nbSpam.current++;
        }

        setEmails((emails) => [
            ...emails,
            {
                uniqueId,
                type,
                x,
                y,
            },
        ]);
    };

    const removeEmail = (e, uniqueId, isShoot) => {
        // stop propagation
        e?.stopPropagation();

        if (isShoot) {
            if (emails.find(email => email.uniqueId === uniqueId).type === "spam" || emails.find(email => email.uniqueId === uniqueId).type === "incognito") {
                setScore(score + 1);
            } else {
                setScore(score - 1);
            }
        }

        setEmails((emails) =>
            emails.filter((email) => email.uniqueId !== uniqueId)
        );
    };

    useEffect(() => {
        const interval = setInterval(() => {
            createEmail();
        }, 1000);

        play("general");
        
        return () => {
            clearInterval(interval);
            stop();
        };
    }, []);

    function startShoot() {
        if (finger.current) {
            finger.current.classList.add("gameEmail_finger--active")
            play("spam_gun")

            setTimeout(() => {
                finger?.current && finger.current.classList.remove("gameEmail_finger--active")
            }, 350);
        }
    }

    return (
        <div className="game gameEmail">
            <Timer ref={timerRef} duration={DURATION} onFinish={() => finishGame()} />

            <div className="gameEmail_container" onClick={() => startShoot()}>
                <Canvas camera={{ position: [0, 0, 0], fov: 80 }}>
                    <ambientLight intensity={0.8} />
                    <fog attach="fog" color="#000000" near={18} far={30} />
                    <Walls />
                    <CameraRig finger={finger} />
                    {emails.map((email) => (
                        <Email
                            removeEmail={removeEmail}
                            uniqueId={email.uniqueId}
                            key={email.uniqueId}
                            type={email.type}
                            x={email.x}
                            y={email.y}
                        />
                    ))}
                </Canvas>
                <div ref={finger} className="gameEmail_finger"></div>
            </div>
        </div>
    );
};

function CameraRig({ finger }) {
    useFrame((state) => {

        if (window.innerWidth >= 1024) {
            state.camera.position.set(-state.pointer.x * 5, -state.pointer.y * 3, 0);
            state.camera.lookAt(-state.pointer.x * 4, -state.pointer.y * 2, -Math.PI);
        }

        if (finger.current) {
            finger.current.classList[state.pointer.x > 0 ? "add" : "remove"]("gameEmail_finger--reverse")
        }
    });
}

export default GameEmail;

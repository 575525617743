import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import HomeVideo from '../../assets/img/logo_intro.mp4'
import HDFImage from '../../assets/img/logo_hdf.svg'
import { SoundBackgroundContext } from '../../Components/sound/sound-background';

const TemplateHome = () => {

    const { play } = useContext(SoundBackgroundContext)

    return (
        <main className="home">
            <h1 className="home_logo"><video src={HomeVideo} alt="Les jeux olympiques de la sobriété" playsInline={true} muted={true} autoPlay={true}></video></h1>
            <h2 className="home_texte">Ce jeu existe aussi dans la vraie vie.<br />mais n'est pas aussi marrant.</h2>
            <p><Link to={"/lumiere"} onClick={() => play("click")} className='bouton bouton--reverse'>D<span className='accent accent_aigu'>é</span>marrer</Link></p>
            <a className="home_hdf" href="https://www.havasdigitalfactory.com/" target="_blank" rel="noreferrer"><img src={HDFImage} alt="" /></a>
        </main >
    )
};

export default TemplateHome;
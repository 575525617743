import React from 'react';
import GameContainer from '../Components/gameContainer/gameContainer';
import GameTemperatureCanvas from '../Games/gameTemperatureCanvas/gameTemperatureCanvas';

const TemplateJeuTemperature = () => {
    return (
        <main>
            <GameContainer
                Game={GameTemperatureCanvas}
                introTitre="TEMPURA-TURE !​"
                introTexte="« Maintenez la température à 19° ! »"
                introBouton="Jouer"
                finVictoireTitre="Température parfaite !​"
                finVictoireTexte=""
                finDefaiteTitre="Defaite Titre"
                finDefaiteTexte="Defaite Texte"
                finBouton="Jeu suivant"
                nextStep="/webcam"
            />
        </main>
    )
};

export default TemplateJeuTemperature;
class Score {

    static reset() {
        this.score = {};
    }

    static update(gameName, points) {
        if (typeof (this.score) === "undefined") this.reset();
        this.score[gameName] = points;
    }

    static get() {
        let total = 0;
        for (var key in this.score) total += this.score[key];

        if (total > 960)
            return ["A", "Ah bah bravo !", 0, "Vous n’avez plus rien à prouver.", "A++"]
        else if (total > 900)
            return ["A", "Ah bah bravo !", 0, "Vous n’avez plus rien à prouver.", "A+"]
        else if (total > 800)
            return ["A", "Ah bah bravo !", 0, "Vous n’avez plus rien à prouver."]
        else if (total > 700)
            return ["B", "Bien, mais bof !", 1, "Si proche de la perfection !"]
        else if (total > 600)
            return ["C", "C'est pas ouf !", 2, "Vous y êtes presque !"]
        else if (total > 500)
            return ["D", "Défaite", 3, "Ne lâchez rien, vous allez y arriver."]
        else if (total > 350)
            return ["E", "Extrêmement nul", 4, "Ne restez pas sur un échec"]
        else if (total > 200)
            return ["F", "Fiasco !!", 5, "Ça peut arriver… Allez, on s’y remet."]
        else
            return ["G", "Game over", 6, "Là, on vous soupçonne de le faire exprès…"]
    }
}

export default Score;
import React, { useContext } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { SoundBackgroundContext } from '../../Components/sound/sound-background';
import Timer from '../../Components/timer/timer';

const GameWebcam = ({ onFinish }) => {

    const DURATION = 30;
    const timerRef = useRef();

    const finishGame = (success) => {
        let ratioScore = timerRef.current.getRatioScore();
        const length = webcams.current.filter(webcam => webcam.on).length;
        let points = ratioScore * 50 * ((NB_WEBCAM - length) / NB_WEBCAM);
        onFinish(success, points)
    }

    const { play, stop } = useContext(SoundBackgroundContext)

    const vitesse_max = useRef(0.2 * Math.pow(window.innerWidth * window.innerHeight, 1 / 4));
    const RANDOM_RESTART = 0.0125;

    const webcams = useRef([]);
    const gameWebcam_box = useRef(null);
    const buttons = useRef(null);
    const [update, nbUpdate] = useState(1);

    const size = useRef([window.innerWidth, window.innerHeight])

    const resize = () => {
        size.current = [window.innerWidth, window.innerHeight];
        vitesse_max.current = 0.2 * Math.pow(window.innerWidth * window.innerHeight, 1 / 4);
        nbUpdate(update => update + 1);
    }

    const NB_WEBCAM = 10;
    const images = useRef([
        require("./assets/perso01.gif"),
        require("./assets/perso02.gif"),
        require("./assets/perso03.gif"),
        require("./assets/perso04.gif"),
        require("./assets/perso05.gif"),
        require("./assets/perso06.gif"),
        require("./assets/perso07.gif"),
        require("./assets/perso08.gif"),
        require("./assets/perso09.gif"),
        require("./assets/perso10.gif")
    ]);

    useEffect(() => {
        window.addEventListener("resize", resize);

        /*
            INIT DES POINTS DE WEBCAMS
        */
        for (let i = 0; i < NB_WEBCAM; i++) {
            let posX = Math.floor((size.current[0] * .75) * Math.random());
            let posY = Math.floor((size.current[1] * .75) * Math.random());
            let angle = Math.PI * 2 * Math.random();
            let vitesse = 2 + vitesse_max.current * Math.random();
            let webcam = {
                id: i,
                image: images.current[i % images.current.length],
                on: true,
                x: posX,
                y: posY,
                vX: vitesse * Math.cos(angle),
                vY: vitesse * Math.sin(angle)
            }
            webcams.current.push(webcam);
        }
        nbUpdate(update => update + 1);

        /*
            CHANGEMENT POSITION DES WEBCAMS
        */
        let req;
        const step = () => {
            if (gameWebcam_box.current && webcams.current) {
                if (!buttons.current || buttons.current.length === 0) {
                    buttons.current = gameWebcam_box.current.querySelectorAll(".gameWebcam_webcam");
                }

                buttons.current.forEach(button => {
                    let webcam = webcams.current.filter(webcam => webcam.id === parseInt(button.dataset.id))[0];

                    let currentVitesse = Math.sqrt(webcam.vX * webcam.vX + webcam.vY * webcam.vY);
                    if (currentVitesse > vitesse_max.current) {
                        let ratio = 0.99;
                        webcam.vX = webcam.vX * ratio;
                        webcam.vY = webcam.vY * ratio;
                    }

                    webcam.x += webcam.vX;
                    if (webcam.x < 0) {
                        webcam.x = 0;
                        webcam.vX = Math.abs(webcam.vX);
                    }
                    if (webcam.x + button.clientWidth > size.current[0]) {
                        webcam.x = size.current[0] - button.clientWidth;
                        webcam.vX = -Math.abs(webcam.vX);
                    }
                    webcam.y += webcam.vY;
                    if (webcam.y < 0) {
                        webcam.y = 0;
                        webcam.vY = Math.abs(webcam.vY);
                    }
                    if (webcam.y + button.clientHeight > size.current[1]) {
                        webcam.y = size.current[1] - button.clientHeight;
                        webcam.vY = -Math.abs(webcam.vY);
                    }
                    button.style.top = webcam.y + "px";
                    button.style.left = webcam.x + "px";
                });
            }
            req = requestAnimationFrame(step);
        }
        req = requestAnimationFrame(step);

        /*
            RALLUMAGE DES ECRANS
        */
        let interval = setInterval(() => {
            let isAnyModif = false;
            webcams.current.filter(webcam => !webcam.on).forEach(webcam => {
                if (Math.random() < RANDOM_RESTART) {
                    webcam.on = true;
                    isAnyModif = true;
                }
            });
            if (isAnyModif) {
                nbUpdate(update => update + 1);
            }
        }, 100);

        play("webcam_background");

        return () => {
            window.removeEventListener("resize", resize);
            webcams.current = [];
            cancelAnimationFrame(req);
            clearInterval(interval);
            stop();
        }
    }, [])

    const changeId = (id) => {
        play("webcam_click");

        webcams.current.filter(webcam => webcam.id === id).forEach(webcam => webcam.on = false);
        const length = webcams.current.filter(webcam => webcam.on).length;

        if (length > 0) {
            nbUpdate(update + 1);
        } else {
            finishGame(1);
        }
    }

    const mouseMove = (e) => {
        if (gameWebcam_box.current && webcams.current && buttons.current) {
            buttons.current.forEach(button => {
                let webcam = webcams.current.filter(webcam => webcam.id === parseInt(button.dataset.id))[0];
                let mouseX = e.clientX;
                let mouseY = e.clientY;

                let buttonX = webcam.x + button.clientWidth * 0.5;
                let buttonY = webcam.y + button.clientHeight * 0.5;

                let width = buttonX - mouseX;
                let height = buttonY - mouseY;
                let distance = Math.sqrt(width * width + height * height)
                if (distance < 200) {
                    webcam.vX += 0.00075 * (200 - Math.abs(width)) * (width / Math.abs(width))
                    webcam.vY += 0.00075 * (200 - Math.abs(height)) * (height / Math.abs(height))
                }
            });
        }
    }

    const getRatio = () => {
        let ratio = Math.floor(0.4 * Math.pow(size.current[0] * size.current[1], 1 / 4))
        return ratio;
    }

    return (
        <div
            className="game gameWebcam"
            data-update={update}
            onMouseMove={e => mouseMove(e)}
            style={{
                backgroundImage: "url(" + require("./assets/fond.jpg") + ")",
            }}
        >
            <Timer ref={timerRef} duration={DURATION} onFinish={() => finishGame(-1)} />
            <div ref={gameWebcam_box} className={"gameWebcam_box"} style={{ fontSize: getRatio() }}>
                <div className="gameWebcam_objet gameWebcam_objet--planete1" style={{ transform: "scale(" + (getRatio() / 13) + ")" }}><div className="gameWebcam_objet--rotateParent"><div className="gameWebcam_objet--rotateEnfant"></div></div></div>
                <div className="gameWebcam_objet gameWebcam_objet--satellite" style={{ transform: "scale(" + (getRatio() / 13) + ")" }}><div className="gameWebcam_objet--rotateParent"><div className="gameWebcam_objet--rotateEnfant"></div></div></div>
                <div className="gameWebcam_objet gameWebcam_objet--saturne" style={{ transform: "scale(" + (getRatio() / 13) + ")" }}><div className="gameWebcam_objet--rotateParent"><div className="gameWebcam_objet--rotateEnfant"></div></div></div>
                <div className="gameWebcam_objet gameWebcam_objet--tennis" style={{ transform: "scale(" + (getRatio() / 13) + ")" }}><div className="gameWebcam_objet--rotateParent"><div className="gameWebcam_objet--rotateEnfant"></div></div></div>
                <div className="gameWebcam_objet gameWebcam_objet--terre" style={{ transform: "scale(" + (getRatio() / 13) + ")" }}><div className="gameWebcam_objet--rotateParent"><div className="gameWebcam_objet--rotateEnfant"></div></div></div>
                <div className="gameWebcam_objet gameWebcam_objet--planete2" style={{ transform: "scale(" + (getRatio() / 13) + ")" }}><div className="gameWebcam_objet--rotateParent"><div className="gameWebcam_objet--rotateEnfant"></div></div></div>
                <div className="gameWebcam_objet gameWebcam_objet--saturne2" style={{ transform: "scale(" + (getRatio() / 13) + ")" }}><div className="gameWebcam_objet--rotateParent"><div className="gameWebcam_objet--rotateEnfant"></div></div></div>
                <div className="gameWebcam_objet gameWebcam_objet--fusee" style={{ transform: "scale(" + (getRatio() / 13) + ")" }}><div className="gameWebcam_objet--rotateParent"><div className="gameWebcam_objet--rotateEnfant"></div></div></div>
                <div className="gameWebcam_objet gameWebcam_objet--planete3" style={{ transform: "scale(" + (getRatio() / 13) + ")" }}><div className="gameWebcam_objet--rotateParent"><div className="gameWebcam_objet--rotateEnfant"></div></div></div>
                {
                    webcams.current.map(webcam => {
                        return (
                            <button
                                className={"gameWebcam_webcam " + (webcam.on ? "" : "gameWebcam_webcam--hidden")}
                                key={"gameWebcam" + webcam.id}
                                data-id={webcam.id}
                                style={{ top: webcam.y + "px", left: webcam.x + "px" }}
                                onClick={() => changeId(webcam.id)}>
                                <span className="gameWebcam__picto gameWebcam__picto--on" style={{ backgroundImage: "url(" + require("./assets/cam-on.png") + ")" }}></span>
                                <span className="gameWebcam__picto gameWebcam__picto--off" style={{ backgroundImage: "url(" + require("./assets/cam-off.png") + ")" }}></span>
                                <img src={webcam.image} alt="" draggable={false} />
                            </button>
                        )
                    })
                }
            </div>
        </div>
    )
};

export default GameWebcam;
import { useFrame } from "@react-three/fiber";
import { useTexture } from "@react-three/drei";
import bgImage from "./assets/bg.jpg"
import * as THREE from "three";

const width = 16;
const height = 9;
const deep = 2;
const bgRepeat = 3
const bgSpeed = 0.5

const Walls = () => {

  const [bgLeft, bgTop, bgRight, bgBot] = useTexture([bgImage, bgImage, bgImage, bgImage], ([bgLeft, bgTop, bgRight, bgBot]) => {
    bgLeft.wrapS = bgLeft.wrapT = THREE.RepeatWrapping
    bgLeft.repeat.set(bgRepeat, 1)
    bgTop.wrapS = bgTop.wrapT = THREE.RepeatWrapping
    bgTop.repeat.set(1, bgRepeat)
    bgRight.wrapS = bgRight.wrapT = THREE.RepeatWrapping
    bgRight.repeat.set(bgRepeat, 1)
    bgBot.wrapS = bgBot.wrapT = THREE.RepeatWrapping
    bgBot.repeat.set(1, bgRepeat)
  })

  useFrame((state, delta) => {
    bgLeft.offset.x += delta * bgSpeed
    bgTop.offset.y -= delta * bgSpeed
    bgRight.offset.x -= delta * bgSpeed
    bgBot.offset.y += delta * bgSpeed
  })
 
  return (
    <>
      <mesh
        position={[-width / 2, 0, (-width * deep) / 2]}
        rotation-y={Math.PI / 2}
      >
        <planeGeometry args={[width * deep, height]} />
        <meshBasicMaterial map={bgLeft}/>
      </mesh>
      <mesh
        position={[width / 2, 0, (-width * deep) / 2]}
        rotation-y={-Math.PI / 2}
      >
        <planeGeometry args={[width * deep, height]} />
        <meshBasicMaterial map={bgRight}/>
      </mesh>
      <mesh
        position={[0, height / 2, (-width * deep) / 2]}
        rotation-x={Math.PI / 2}
      >
        <planeGeometry args={[width, width * deep]} />
        <meshBasicMaterial map={bgTop}/>
      </mesh>
      <mesh
        position={[0, -height / 2, (-width * deep) / 2]}
        rotation-x={-Math.PI / 2}
      >
        <planeGeometry args={[width, width * deep]} />
        <meshBasicMaterial map={bgBot}/>
      </mesh>
    </>
  );
};

export default Walls;

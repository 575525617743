import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// TPL PARTS
import Header from './Components/header/header';
import Footer from './Components/footer/footer';
import TemplateHome from './Templates/templateHome/templateHome';
import TemplateFin from './Templates/templateFin/templateFin';
import TemplateJeuLumiere from './Templates/templateJeuLumiere';
import TemplateJeuEmail from './Templates/templateJeuEmail';
import TemplateJeuHeure from './Templates/templateJeuHeure';
import TemplateJeuTemperature from './Templates/templateJeuTemperature';
import TemplateJeuTemperatureCanvas from './Templates/templateJeuTemperatureCanvas';
import TemplateJeuTemperatureCanvas2 from './Templates/templateJeuTemperatureCanvas2';
import TemplateJeuWebcam from './Templates/templateJeuWebcam';
import { SoundBackgroundProvider } from './Components/sound/sound-background';


const App = () => {

  return (
    <div className="app">
      <SoundBackgroundProvider>

        <Router>

          <Header />


          <Routes>
            <Route path="/" element={<TemplateHome />} />
            <Route path="/lumiere" element={<TemplateJeuLumiere />} />
            <Route path="/email" element={<TemplateJeuEmail />} />
            <Route path="/heure" element={<TemplateJeuHeure />} />
            <Route path="/temperature" element={<TemplateJeuTemperature />} />
            <Route path="/temperatureCanvas" element={<TemplateJeuTemperatureCanvas />} />
            <Route path="/temperatureCanvas2" element={<TemplateJeuTemperatureCanvas2 />} />
            <Route path="/webcam" element={<TemplateJeuWebcam />} />
            <Route path="/fin" element={<TemplateFin />} />
          </Routes>


          <Footer />

        </Router>

      </SoundBackgroundProvider>
    </div>
  );
};

export default App;

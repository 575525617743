import React from 'react';

import GameContainer from '../Components/gameContainer/gameContainer';
import GameWebcam from '../Games/gameWebcam/gameWebcam';
import GameVideo from '../Games/gameWebcam/assets/logo_video.mp4';
import finVideo from '../Games/gameWebcam/assets/video_fin.mp4';

const TemplateJeuWebcam = () => {
    return (
        <main>
            <GameContainer
                Game={GameWebcam}
                gameId={5}
                introVideo={GameVideo}
                introVideoWidth={492}
                introVideoHeight={526}
                introTexte="<span>»</span> Éteignez-moi toutes ces caméras ! »"
                introBouton="Jouer"
                finVictoireVideo={finVideo}
                finVictoireTexte="Euhh…, y’a quelqu’un ?"
                finDefaiteVideo={finVideo}
                finDefaiteTexte="Euhh…, y’a quelqu’un ?"
                finBouton="Découvrir mon score"
                nextStep="/fin"
            />
        </main>
    )
};

export default TemplateJeuWebcam;
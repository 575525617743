import { useFrame } from "@react-three/fiber";
import { useTexture } from "@react-three/drei";
import incognito_sprites from "./assets/incognito_sprites.png";
import mail1_sprites from "./assets/mail1_sprites.png";
import mail2_sprites from "./assets/mail2_sprites.png";
import mail3_sprites from "./assets/mail3_sprites.png";
import spam1_sprites from "./assets/spam1_sprites.png";
import spam2_sprites from "./assets/spam2_sprites.png";
import explosion_sprites from "./assets/explosion.png";
import explosionError_sprites from "./assets/explosion-error.png";
import { useMemo, useRef } from "react";

const z = 30;

let liste = {
  email: [
    [mail1_sprites, 8],
    [mail2_sprites, 24],
    [mail3_sprites, 3],
  ],
  spam: [
    [spam1_sprites, 12],
    [spam2_sprites, 8],
  ],
  incognito: [[incognito_sprites, 4]],
  explosion: [[explosion_sprites, 7]],
  explosionError: [[explosionError_sprites, 7]],
};

const Email = ({ removeEmail, uniqueId, type, x, y }) => {
  const emailRef = useRef();
  const spriteRef = useRef();
  const infos = useRef();
  const bgMailSrc = useRef();
  const tiles = useRef();
  const elapsedTime = useRef(0);
  const elapsed = useRef(0);
  const explosionRef = useRef();
  const explosionErrorRef = useRef();

  if (!infos.current) {
    infos.current = liste[type][Math.floor(liste[type].length * Math.random())];
    bgMailSrc.current = infos.current[0];
    tiles.current = infos.current[1];
  }

  const bgEmail = useTexture(infos.current[0]);
  const clone = useMemo(() => bgEmail.clone(), [bgEmail]);
  clone.repeat.set(1 / tiles.current, 1);

  const bgExplosion = useTexture(liste.explosion[0][0]);
  const cloneExplosion = useMemo(() => bgExplosion.clone(), [bgExplosion]);
  cloneExplosion.repeat.set(1 / liste.explosion[0][1], 1);
  cloneExplosion.offset.x = -1;

  const bgExplosionError = useTexture(liste.explosionError[0][0]);
  const cloneExplosionError = useMemo(() => bgExplosionError.clone(), [bgExplosionError]);
  cloneExplosionError.repeat.set(1 / liste.explosionError[0][1], 1);
  cloneExplosionError.offset.x = -1;

  const explosion = (e) => {
    e.stopPropagation();
    elapsed.current = 0;

    if (type !== "email") {
      explosionRef.current.visible = true;
    } else {
      explosionErrorRef.current.visible = true;
    }

    //after 1 second, remove the email
    setTimeout(() => {
      removeEmail(null, uniqueId, true);
    }
      , 1000 / 12 * liste.explosion[0][1]);
  };

  useFrame((state, delta) => {
    elapsedTime.current += delta;
    elapsed.current += delta;

    emailRef.current.position.z += delta * 5;

    if (emailRef.current.position.z > 0) {
      removeEmail(null, uniqueId, false);
    }

    //Animation
    if (emailRef.current) {

      if (type !== "incognito") {
        let currentTile = Math.floor(elapsedTime.current * 12) % tiles.current;
        clone.offset.x = currentTile / tiles.current;
      } else {
        let currentTile = elapsedTime.current > 2 ? 3 : 0;
        if (elapsedTime.current > 1 && elapsedTime.current < 2) {
          currentTile = (Math.floor((elapsedTime.current - 1) * 10) % 2) + 1;
        }
        clone.offset.x = currentTile / tiles.current;
      }
    }

    if (explosionRef.current) {
      let currentTile =
        Math.floor(elapsed.current * 12) & liste.explosion[0][1];
      cloneExplosion.offset.x = currentTile / liste.explosion[0][1];
    }

    if (explosionErrorRef.current) {
      let currentTile =
        Math.floor(elapsed.current * 12) & liste.explosionError[0][1];
      cloneExplosionError.offset.x = currentTile / liste.explosionError[0][1];
    }
  });

  return (
    <mesh onClick={explosion} ref={emailRef} position={[x, y, -z]}>
      <sprite scale={3}>
        <spriteMaterial ref={spriteRef} map={clone} />
      </sprite>{" "}
      <sprite visible={false} ref={explosionRef} scale={3.5}>
        <spriteMaterial map={cloneExplosion} />
      </sprite>
      <sprite visible={false} ref={explosionErrorRef} scale={3.5}>
        <spriteMaterial map={cloneExplosionError} />
      </sprite>
    </mesh>
  );
};

export default Email;

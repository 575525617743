import React from 'react';

import GameContainer from '../Components/gameContainer/gameContainer';
import GameEmail from '../Games/gameEmail/gameEmail';
import GameVideo from '../Games/gameEmail/assets/logo_video.mp4';
import finVideo from '../Games/gameEmail/assets/video_fin.mp4';

const TemplateJeuEmail = () => {

    return (
        <main>
            <GameContainer
                Game={GameEmail}
                gameId={2}
                introVideo={GameVideo}
                introVideoWidth={1286}
                introVideoHeight={912}
                introTexte="<span>»</span> Éliminez-moi ces mails inutiles ! »"
                introBouton="Jouer"
                finVictoireVideo={finVideo}
                finVictoireTexte="Indésirables mails. Ils reviennent !!!!!!!"
                finDefaiteVideo={finVideo}
                finDefaiteTexte="Indésirables mails. Ils reviennent !!!!!!!"
                finBouton="Jeu suivant"
                nextStep="/heure"
            />
        </main>
    )
};

export default TemplateJeuEmail;
import React from 'react';
import { useEffect } from 'react';

const GameTemperature2 = () => {
    useEffect(() => {

        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        const TOLERANCE = 10;
        const background = new Image();
        background.src = require('./assets/land.png');

        background.addEventListener("load", () => {
            canvas.width = background.width / TOLERANCE;
            canvas.height = background.height / TOLERANCE;
            ctx.drawImage(background, 0, 0, canvas.width, canvas.height);

            let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            let safeArea = []

            for (let i = 0; i < canvas.height; i++) {

                let tabRow = [];

                for (let j = 0; j < canvas.width; j++) {
                    let index = 4 * (i * canvas.width + j);
                    let opacity = imgData.data[index + 3];
                    opacity = (opacity < 0.35 ? 0 : 1);
                    tabRow.push(opacity);
                }

                safeArea.push(tabRow);
            }
        });
    }, [])

    return (
        <div className="game gameTemperatureCanvas" >
            <div className="gameTemperatureCanvas__container">
                <canvas id="canvas" />
            </div>
        </div>
    )

};

export default GameTemperature2;
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Score from '../../Tools/Score';
import HDFImage from '../../assets/img/logo_hdf.png'
import pictoFacebook from '../../assets/img/picto_facebook.svg'
import pictoTwitter from '../../assets/img/picto_twitter.svg'
import pictoLinkedin from '../../assets/img/picto_linkedin.svg'
import { SoundBackgroundContext } from '../../Components/sound/sound-background';

const TemplateFin = () => {

    const score = Score.get();
    const url_share = "https://bonne-sobriyearte.havasfactory.com";
    const { play, stop } = useContext(SoundBackgroundContext)

    useEffect(() => {
        play("general")

        return () => {
            stop()
        }
    }, []);

    const go = (action, rs) => {
        play("click");
        if (typeof (window._paq) !== "undefined") {
            window._paq.push(['trackEvent', action, rs]);
        }
    }

    return (
        <main className={"fin fin--score" + score[0]}>
            <h1 className='fin_titre'>Votre score :</h1>
            <div className='fin_image'>
                <svg xmlns="http://www.w3.org/2000/svg" width="176.484" height="263.127" viewBox="0 0 176.484 263.127">

                    <g id="Groupe_677" data-name="Groupe 677" transform="translate(-595 -252.999)">

                        <g>
                            <path id="Groupe_A" d="M2.89,5.036C2.773,14.624,2.538,33.8,2.538,33.8s45.091-.055,65.855.162a6.045,6.045,0,0,0,1.982-.2,6.045,6.045,0,0,0,2.037-1.382L87.839,18.473a161.994,161.994,0,0,1-13.7-14.558,3.76,3.76,0,0,0-1.035-.97A3.811,3.811,0,0,0,71.017,2.7c-21.739,1.46-67.871-.82-67.871-.82Z" transform="translate(595 253)" fill="#fff" />
                            <path d="M1.015,5.036.663,33.8a1.9,1.9,0,0,0,1.875,1.875q11.357-.013,22.714,0c13.074.01,26.149.025,39.223.129,1.565.013,3.159.115,4.722.03,2.894-.159,4.718-2.284,6.744-4.111L89.165,19.8a1.893,1.893,0,0,0,0-2.652q-5.5-5.184-10.52-10.849Q77.413,4.9,76.215,3.481a7.127,7.127,0,0,0-2.608-2.344C72.063.508,70.21.881,68.58.964c-6.359.326-12.733.366-19.1.34C36.788,1.253,24.094.876,11.412.364,8.656.253,5.9.137,3.146,0A1.913,1.913,0,0,0,1.271,1.876q-.128,1.581-.256,3.16c-.195,2.406,3.556,2.393,3.75,0q.128-1.579.256-3.16L3.146,3.751c7.432.367,14.87.635,22.307.858,13.223.4,26.483.712,39.708.251q2.331-.081,4.66-.214c.748-.044,1.889-.339,2.522.058a7.173,7.173,0,0,1,1.221,1.428q.673.8,1.358,1.592A164.336,164.336,0,0,0,86.513,19.8V17.147l-11.57,10.43-2.755,2.484c-.771.695-1.635,1.775-2.7,1.995a21.372,21.372,0,0,1-4.247,0q-9.105-.075-18.21-.095-18.282-.057-36.564-.042-3.967,0-7.933,0L4.413,33.8,4.765,5.036c.03-2.414-3.72-2.415-3.75,0" transform="translate(595 253)" fill="#050606" />
                        </g>

                        <g transform="translate(595 253)">
                            <path id="Groupe_B" d="M2.507,42.886q-.093,13.012.835,26c3.955-.489,7.934.339,11.891.813a134,134,0,0,0,15.483.652l52.679.184a3.516,3.516,0,0,0,1.794-.312,3.682,3.682,0,0,0,.964-.937L96.87,55.979a1.043,1.043,0,0,0,.019-1.6L87.684,42.086a3.335,3.335,0,0,0-1.016-1.012,3.187,3.187,0,0,0-1.62-.227c-23.108.805-46.229-1.033-69.349-.813a78.293,78.293,0,0,0-12.785.892Z" fill="#fff" />
                            <path d="M.007,42.886q-.083,13.014.835,26c.087,1.233,1.08,2.657,2.5,2.5a39.973,39.973,0,0,1,9.8.541,98.448,98.448,0,0,0,10.545.844c6.893.188,13.8.1,20.695.128l21.121.074,10.437.036,4.969.017c1.694.006,3.633.262,5.207-.493a9.605,9.605,0,0,0,3.029-3l3.368-4.184c2.162-2.685,4.525-5.3,6.515-8.112a3.52,3.52,0,0,0,.019-4.128c-.988-1.509-2.185-2.916-3.267-4.36-2.052-2.74-4.079-5.5-6.161-8.219a5.093,5.093,0,0,0-4.156-2.2c-1.779-.053-3.586.108-5.368.143q-5.2.1-10.4.063c-6.937-.04-13.871-.225-20.8-.427-7.761-.226-15.521-.474-23.285-.565s-15.672-.34-23.358.963A2.428,2.428,0,0,0,.5,40.261q-.2.981-.407,1.96A2.562,2.562,0,0,0,1.842,45.3a2.526,2.526,0,0,0,3.076-1.746q.2-.981.406-1.961L3.578,43.337c6.256-1.061,12.734-.852,19.057-.812,6.442.04,12.882.2,19.321.389,13.039.375,26.085.839,39.131.546q1.485-.033,2.971-.08c.33-.01.66-.024.99-.033s.655.109.409-.061A9.986,9.986,0,0,1,87.01,45.33L88.5,47.312l3.414,4.559,1.634,2.18a8.907,8.907,0,0,1,1.3,1.817V54.539c-.012.053-.763.94-.967,1.194q-.767.949-1.531,1.9l-3.062,3.8-3.062,3.8-1.378,1.712c-.087.108-1.254,1.323-.708,1.067a7.232,7.232,0,0,1-2.233.015l-2.485-.009-4.969-.017-9.94-.035c-13.2-.046-26.414.1-39.61-.17a95.637,95.637,0,0,1-10.617-.72c-3.637-.479-7.265-1.1-10.941-.693l2.5,2.5q-.918-12.981-.835-26c.021-3.218-4.979-3.222-5,0" fill="#161615" />
                        </g>

                        <g>
                            <path id="Groupe_C" d="M2.734,78.074l.153,30.009q15.719.2,31.438.39,3.961.048,7.924.1,24.438.3,48.875.606c3.006.037,6.2.021,8.745-1.642a16.116,16.116,0,0,0,3.86-4.112c2.745-3.714,6.806-6.145,9.551-9.858-3.4-4.41-8.124-10.1-11.528-14.513a4.46,4.46,0,0,0-1.54-1.448,4.3,4.3,0,0,0-2.124-.2c-31.533,2.3-63.72-.816-95.354.668" transform="translate(595 253)" fill="#fff" />
                            <path d="M.859,78.074l.153,30.009a1.906,1.906,0,0,0,1.875,1.875q24.419.3,48.838.606l24.437.3,11.97.149c3.553.044,7.4.4,10.788-.918,3.713-1.448,5.356-4.78,7.975-7.5,2.646-2.75,5.685-5.019,8-8.082a1.907,1.907,0,0,0-.293-2.272c-2.11-2.73-4.28-5.411-6.44-8.1q-1.587-1.974-3.162-3.956a19.093,19.093,0,0,0-3.259-3.821c-1.536-1.119-3.33-.854-5.113-.733q-3.158.216-6.318.362-5.849.271-11.7.355c-16.174.255-32.343-.359-48.515-.528-9.122-.1-18.248-.045-27.362.377-2.4.11-2.417,3.862,0,3.75,31.45-1.452,62.953,1.587,94.384-.6.825-.058,1.936-.366,2.614.2a13.174,13.174,0,0,1,1.632,2.032q1.656,2.115,3.342,4.205c2.428,3.026,4.875,6.036,7.248,9.1q-.146-1.137-.293-2.273c-1.94,2.565-4.577,4.5-6.85,6.75-2.2,2.174-3.774,5.532-6.6,6.961-2.86,1.446-6.49.98-9.583.94l-11.969-.147-47.326-.588-26.448-.328,1.875,1.875L4.609,78.074c-.012-2.413-3.762-2.418-3.75,0" transform="translate(595 253)" fill="#050606" />
                        </g>

                        <g>
                            <path id="Groupe_D" d="M2.954,115.583a205.706,205.706,0,0,0,1.092,31.7c2.869-1.227,6.067-1.227,9.169-1.21l98.779.563a5.268,5.268,0,0,0,2.551-.414,5.516,5.516,0,0,0,1.525-1.417L127.9,130.627a51.3,51.3,0,0,1-10.751-12.75,5.531,5.531,0,0,0-1.795-2.135,4.934,4.934,0,0,0-2.415-.434c-36.393-.309-73.608-.789-109.987.275" transform="translate(595 253)" fill="#fff" />
                            <path d="M1.079,115.583a208.919,208.919,0,0,0,1.092,31.7C2.29,148.351,3.3,149.6,4.545,149.1c3.69-1.5,7.764-1.148,11.663-1.126l13.221.076,26.191.149,52.383.3c2.176.012,4.8.412,6.868-.377s3.4-3.032,4.763-4.663l9.594-11.5a1.9,1.9,0,0,0,0-2.651,49.906,49.906,0,0,1-7.969-8.588c-1.1-1.518-2-3.156-3.057-4.695a5.573,5.573,0,0,0-4.638-2.57c-4.422-.234-8.9-.088-13.326-.128s-8.831-.078-13.246-.113c-17.972-.144-35.946-.232-53.919-.063q-15.062.143-30.119.563c-2.408.07-2.418,3.82,0,3.75,17.5-.509,35.014-.664,52.524-.655,17.447.01,34.893.182,52.34.336l3.66.032c.856.007,2.242-.228,3.018.25.671.413,1.083,1.493,1.485,2.141q.852,1.375,1.789,2.7a53.806,53.806,0,0,0,8.806,9.7V129.3q-4.158,4.981-8.314,9.965c-1.337,1.6-2.594,3.382-4.073,4.854a2.675,2.675,0,0,1-2.195.644c-1.081,0-2.162-.012-3.243-.019L82.809,144.6l-51.385-.293-14.467-.082c-4.434-.026-9.208-.448-13.409,1.255l2.373,1.808a208.92,208.92,0,0,1-1.092-31.7c.1-2.413-3.65-2.408-3.75,0" transform="translate(595 253)" fill="#050606" />
                        </g>

                        <g>
                            <path id="Groupe_E" d="M2.663,153.744c-.4,10-.3,20.024.286,30.015l44.623-.221,46.005-.228,32.684-.162a6.773,6.773,0,0,0,2.793-.41,7.073,7.073,0,0,0,2.24-1.927l10.914-12.433c-4.23-4.943-8.5-8.628-12.733-13.571a5.956,5.956,0,0,0-1.507-1.4,5.676,5.676,0,0,0-2.8-.493c-25.328-.113-49.963.088-75.29.193-15.012.062-32.2.571-47.216.633" transform="translate(595 253)" fill="#fff" />
                            <path d="M.788,153.744q-.582,15.006.286,30.015a1.911,1.911,0,0,0,1.875,1.875l58.227-.289,58.152-.288c2.576-.013,5.177.078,7.749-.049a7.627,7.627,0,0,0,5.7-3.054q5.384-6.12,10.754-12.25a1.931,1.931,0,0,0,0-2.651c-3.073-3.568-6.481-6.812-9.713-10.229-.755-.8-1.5-1.607-2.227-2.431a12.2,12.2,0,0,0-2.509-2.489c-1.985-1.238-4.682-.865-6.912-.873q-14.982-.053-29.965.01c-19.429.064-38.864.06-58.291.379-10.417.171-20.835.4-31.254.449-2.412.011-2.417,3.761,0,3.75,18.233-.081,36.461-.58,54.7-.667,19.613-.093,39.226-.221,58.84-.187q4.11.008,8.221.025c1.163,0,2.3-.151,3.177.721s1.629,1.864,2.459,2.77c3.548,3.868,7.4,7.443,10.827,11.423v-2.651l-9.309,10.6c-1.087,1.239-2.316,3.337-4.088,3.577a54.862,54.862,0,0,1-6.919.067l-28.473.141-56.916.282-32.228.16,1.875,1.875q-.867-14.992-.286-30.015c.093-2.413-3.657-2.409-3.75,0" transform="translate(595 253)" fill="#050606" />
                        </g>

                        <g>
                            <path id="Groupe_F" d="M2.24,190.849q.411,15.454.823,30.909c20.718,3.3,41.828.406,62.789.387,6.7-.006,13.393.283,20.089.456,18.642.482,37.3.068,55.932-.636a7.028,7.028,0,0,0,2.473-.393,7.4,7.4,0,0,0,2.621-2.291l10.689-12.837q-4.59-6.123-9.654-11.843a9.785,9.785,0,0,0-2.75-2.4,9.394,9.394,0,0,0-3.419-.765c-13.41-1.232-26.9-1.093-40.358-.952-32.434.339-66.8.027-99.235.366" transform="translate(595 253)" fill="#fff" />
                            <path d="M.365,190.849q.411,15.454.823,30.909a1.9,1.9,0,0,0,1.377,1.808c21.172,3.324,42.5.427,63.777.454,10.742.014,21.477.59,32.221.652,10.81.063,21.619-.129,32.424-.458q4.578-.14,9.154-.309c2.861-.1,5.292-.194,7.375-2.4,1.948-2.064,3.677-4.381,5.493-6.561l5.973-7.174a1.918,1.918,0,0,0,.293-2.272c-3.2-4.253-6.534-8.412-10.084-12.376a9.4,9.4,0,0,0-5.981-3.4c-2.727-.379-5.506-.511-8.252-.675-10.9-.654-21.829-.555-32.744-.442-21.38.223-42.762.175-64.142.2-11.944.014-23.889.05-35.832.174-2.412.025-2.418,3.775,0,3.75,20.892-.216,41.786-.169,62.679-.191q15.565-.017,31.133-.124c10.591-.088,21.186-.294,31.775.056q4.551.15,9.1.453,2.21.15,4.418.347a6.893,6.893,0,0,1,3.654.971A18.525,18.525,0,0,1,147.62,197q1.412,1.618,2.786,3.27,2.9,3.489,5.631,7.119.147-1.137.293-2.273Q151.3,211.16,146.27,217.2a12.049,12.049,0,0,1-2.2,2.388,5.972,5.972,0,0,1-3.191.54q-3.959.147-7.918.274c-10.556.338-21.118.551-31.68.531-10.511-.021-21.006-.489-31.514-.636-10.43-.146-20.84.481-31.258.881-11.651.448-23.4.585-34.952-1.228l1.376,1.808q-.411-15.454-.823-30.909c-.064-2.408-3.814-2.418-3.75,0" transform="translate(595 253)" fill="#050606" />
                        </g>

                        <g>
                            <path id="Groupe_G" d="M3.339,228.995c-.485,10.073-.347,22.182-.832,32.256,21.656-.036,42.217-.514,63.653-.533l91.408-.082a7.37,7.37,0,0,0,2.886-.389,7.7,7.7,0,0,0,2.534-2.1l11.628-12.838a67.289,67.289,0,0,1-11.04-12.06c-1.013-1.421-2.092-2.973-3.717-3.5a7.371,7.371,0,0,0-2.217-.244l-43.241-.349c-36.668-.3-71.462.894-111.062-.166" transform="translate(595 253)" fill="#fff" />
                            <path d="M1.464,228.995c-.507,10.745-.325,21.51-.832,32.256a1.9,1.9,0,0,0,1.875,1.875c23.384-.043,46.763-.518,70.147-.539l71.178-.063,9.99-.009c2.948,0,6.338.5,8.817-1.429a26.555,26.555,0,0,0,3.17-3.261q1.66-1.835,3.323-3.668l6.81-7.519a1.9,1.9,0,0,0,0-2.652,72.515,72.515,0,0,1-11.187-12.294c-1.556-2.124-3.244-3.783-5.993-4.016-2.991-.253-6.071-.081-9.073-.1q-18.567-.15-37.135-.3c-22.929-.154-45.858.255-68.788.294-13.476.023-26.953-.088-40.427-.446-2.414-.064-2.412,3.686,0,3.75,23.224.617,46.452.456,69.679.268s46.473-.061,69.713.126l10.189.083c1.721.013,3.45-.018,5.17.043a3.713,3.713,0,0,1,3.027,1.668c1.9,2.331,3.559,4.819,5.557,7.077a68.375,68.375,0,0,0,6.616,6.5v-2.652c-3.876,4.279-7.729,8.58-11.628,12.838a4.625,4.625,0,0,1-2.833,1.9,35.78,35.78,0,0,1-4.258.036l-8.741.008-34.964.031c-23.217.021-46.435-.045-69.65.22-12.9.147-25.8.329-38.709.353l1.875,1.875c.507-10.746.325-21.511.832-32.256.114-2.412-3.636-2.407-3.75,0" transform="translate(595 253)" fill="#050606" />
                        </g>

                        <g id="Groupe_656" data-name="Groupe 656" transform="translate(595 253)" style={{ isolation: "isolate", fill: "#040405", fontSize: "23px", fontFamily: "Arial-BoldMT, Arial", fontWeight: "700" }}>
                            <text id="A" transform="translate(9.551 26.944)"><tspan x="0" y="0">{score[4] ? score[4] : "A"}</tspan></text>
                            <text id="B" transform="translate(9.551 63.9)"><tspan x="0" y="0">B</tspan></text>
                            <text id="C" transform="translate(9.551 100.189)"><tspan x="0" y="0">C</tspan></text>
                            <text id="D" transform="translate(9.551 137.813)"><tspan x="0" y="0">D</tspan></text>
                            <text id="E" transform="translate(9.551 174.991)"><tspan x="0" y="0">E</tspan></text>
                            <text id="F" transform="translate(9.551 212.615)"><tspan x="0" y="0">F</tspan></text>
                            <text id="G" transform="translate(9.396 250.097)"><tspan x="0" y="0">G</tspan></text>
                        </g>
                    </g>
                </svg>
                <p className={"fin_image_texte"} style={{ top: (100 * score[2] / 7 - 1) + "%", left: (140 + score[2] * 12) + "px" }}>{score[1]}</p>
            </div>
            <p className={"fin_image_texte2"}>{score[3]}</p>
            <div className='fin_boutons'>
                <Link onClick={() => {
                    Score.reset();
                    go("Rejouer", "Rejouer");
                }} to="/lumiere" className='bouton'>Rejouer</Link>
                <p className='fin_boutons--share'>
                    <span className="fin_label">Partager :</span>
                    <a onClick={() => go("Share", "facebook")} className='fin_share fin_share--facebook bouton bouton--nohover' rel="noreferrer" href={'https://www.facebook.com/sharer/sharer.php?u=' + url_share} target={'_blank'}><img src={pictoFacebook} alt="" /></a>
                    <a onClick={() => go("Share", "twitter")} className='fin_share fin_share--twitter bouton bouton--nohover' rel="noreferrer" href={'https://twitter.com/intent/tweet?text=Bonne%20sobriyearte&url=' + url_share} target={'_blank'}><img src={pictoTwitter} alt="" /></a>
                    <a onClick={() => go("Share", "linkedin")} className='fin_share fin_share--linkedin bouton bouton--nohover' rel="noreferrer" href={'https://www.linkedin.com/sharing/share-offsite/?url=' + url_share} target={'_blank'}><img src={pictoLinkedin} alt="" /></a>
                </p>
            </div>
            <p className='fin_baseline'>
                <strong>On a dépensé beaucoup d’énergie pour que ce jeu en consomme le moins possible.</strong>
                <em>La compression des images, des vidéos et des sons<br /> ont permis de réduire de 50% sa consommation énergétique.</em>
            </p>
            <a className="fin_hdf" href="https://www.havasdigitalfactory.com/" rel="noreferrer" target="_blank"><img src={HDFImage} alt="" /></a>
        </main>
    )
};

export default TemplateFin;
import React from 'react';

import GameContainer from '../Components/gameContainer/gameContainer';
import GameTemperature from '../Games/gameTemperature/gameTemperature';
import GameVideo from '../Games/gameTemperature/assets/logo_video.mp4';
import finVictoire from '../Games/gameTemperature/assets/video_victoire.mp4';
import finDefaite1 from '../Games/gameTemperature/assets/video_defaite1.mp4';
import finDefaite2 from '../Games/gameTemperature/assets/video_defaite2.mp4';

const TemplateJeuTemperature = () => {
    return (
        <main>
            <GameContainer
                Game={GameTemperature}
                gameId={4}
                introVideo={GameVideo}
                introVideoWidth={534}
                introVideoHeight={840}
                introTexte="<span>»</span> Maintenez la température à 19<strong>°</strong> ! »"
                introBouton="Jouer"
                finVictoireVideo={finVictoire}
                finVictoireTexte="Température parfaite "
                finDefaiteVideo={finDefaite1}
                finDefaiteTexte="Tempuraté brulée"
                finDefaite2Video={finDefaite2}
                finDefaite2Texte="Tempuraté givrée"
                finBouton="Jeu suivant"
                nextStep="/webcam"
            />
        </main>
    )
};

export default TemplateJeuTemperature;
import React, { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import Timer from '../../Components/timer/timer';
import data from './data.json';
import data_volcan from './data_volcan.json';
import shrimpNormal from "./assets/shrimp.png"
import { SoundBackgroundContext } from '../../Components/sound/sound-background';

const GameTemperature = ({ onFinish }) => {

    const DURATION = 30;
    const timerRef = useRef();

    const finishGame = () => {
        let ratioScore = timerRef.current.getRatioScoreLinear();

        let nbChocTotal = 0;
        let maxChocType = 0;
        let type = "";

        for (let key in nbChoc.current) {
            nbChocTotal += nbChoc.current[key];
            if (nbChoc.current[key] > maxChocType && key !== "neutre") {
                maxChocType = nbChoc.current[key]
                type = key;
            }
        }

        let success = ratioScore > 0.95 && nbChocTotal === 0 ? 1 : -1;

        if (success === -1 && type === "givree") {
            success = -2;
        }

        let chocScore = (NB_AUTORISED_CHOC - nbChocTotal) / NB_AUTORISED_CHOC;
        chocScore = chocScore <= 0 ? 0.1 : chocScore;

        let points = Math.round(chocScore * 200 * ratioScore);

        onFinish(success, points)
    }

    const { play, stop } = useContext(SoundBackgroundContext)

    const NEED_DEBUG = false;

    const game = useRef(null);
    const shrimpPosition = useRef(null);
    const container = useRef(null);
    const translate = useRef(null);
    translate.current = 0;
    const yMouse = useRef(null);

    const touchPosition = useRef(null);

    const SCALE = 1.25;
    const TOLERANCE = 10;
    const HEIGHT_INITIAL = 1920;
    const WIDTH_INITIAL = 6254;
    const NB_REPETITION = 3;
    const running = useRef(false);

    const NB_AUTORISED_CHOC = 8;
    const canIncrementChoc = useRef(true);
    const nbChoc = useRef({ brulee: 0, neutre: 0, givree: 0 })

    const currentPosition = useRef(null);
    currentPosition.current = { x: 0, y: window.innerHeight * 0.4 };
    const lastPosition = useRef(null);
    lastPosition.current = { x: 0, y: window.innerHeight * 0.4 };

    const shrimpCollision = useRef(null);

    const canvas = useRef(null);
    const canvas2 = useRef(null);

    const [update, nbUpdate] = useState(1);
    const [size, changeSize] = useState(null);

    const etat = useRef("");
    const lastEtat = useRef("")
    const slapped = useRef(false);

    const resize = () => {
        if (game.current) {
            let gameInfos = game.current.getBoundingClientRect();
            changeSize(gameInfos.height);
            nbUpdate(update + 1);
        }
    }

    const onMouseMove = (e) => {
        currentPosition.current.x += e.movementX;
        currentPosition.current.y += e.movementY;
    }

    const onTouchStart = (e) => {
        e.preventDefault();
        touchPosition.current = [e.touches[0].clientX, e.touches[0].clientY];
    }

    const onTouchMove = (e) => {
        e.preventDefault();
        if (touchPosition.current) {
            let diffX = e.touches[0].clientX - touchPosition.current[0];
            let diffY = e.touches[0].clientY - touchPosition.current[1];
            touchPosition.current = [e.touches[0].clientX, e.touches[0].clientY];

            currentPosition.current.x += diffX;
            currentPosition.current.y += diffY;
        }
    }

    const scrollLeft = () => {
        if (container.current) {
            let step = 4.5 * window.innerHeight / 1297
            translate.current -= step;
            container.current.style.transform = `translateX(${translate.current}px)`;

            currentPosition.current.x += step;
        }
    }

    const initShrimpCanvas = () => {
        let canvas3 = document.createElement("canvas");
        let context = canvas3.getContext("2d");

        let image = new Image();
        image.addEventListener("load", () => {
            canvas3.height = Math.floor(SCALE * image.height / TOLERANCE);
            canvas3.width = Math.floor(SCALE * image.width / TOLERANCE);

            context.drawImage(image, 0, 0, canvas3.width, canvas3.height);
            let imgData = context.getImageData(0, 0, canvas3.width, canvas3.height);

            let safeArea = []

            for (let i = 0; i < canvas3.height; i++) {

                let tabRow = [];

                for (let j = 0; j < canvas3.width; j++) {
                    let index = 4 * (i * canvas3.width + j);
                    let opacity = imgData.data[index + 3];
                    opacity = (opacity < 64 ? 0 : 1);
                    tabRow.push(opacity);
                }

                safeArea.push(tabRow);
            }

            shrimpCollision.current = safeArea

            if (NEED_DEBUG) {
                debug();
                window.addEventListener("resize", () => debug())
            }
        })
        image.src = shrimpNormal;
    }

    const setOrientation = (last, current) => {

        if (shrimpPosition.current) {
            if (last < current) {
                shrimpPosition.current.classList.add('down')
                shrimpPosition.current.classList.remove('up')
            } else if (last > current) {
                shrimpPosition.current.classList.remove('down')
                shrimpPosition.current.classList.add('up')
            } else {
                shrimpPosition.current.classList.remove('down')
                shrimpPosition.current.classList.remove('up')
            }
        }

    }

    const checkPosition = () => {

        if (shrimpCollision.current && shrimpPosition.current && lastPosition.current && currentPosition.current && yMouse.current) {

            /*******************/
            /* CHECK COLLISION */
            /*******************/

            let shrimpInfos = shrimpPosition.current.getBoundingClientRect();

            currentPosition.current.x = currentPosition.current.x < -translate.current ? -translate.current : currentPosition.current.x > window.innerWidth - translate.current - shrimpInfos.width ? window.innerWidth - translate.current - shrimpInfos.width : currentPosition.current.x;
            currentPosition.current.y = currentPosition.current.y < 0 ? 0 : currentPosition.current.y > window.innerHeight - shrimpInfos.height ? window.innerHeight - shrimpInfos.height : currentPosition.current.y;

            let imageHeight = HEIGHT_INITIAL;
            let screenHeight = window.innerHeight;
            let ratio = screenHeight / imageHeight;

            let newMousePositionY = currentPosition.current.y / ratio;
            let newMousePositionX = ((currentPosition.current.x /*- translate.current*/) / ratio) % WIDTH_INITIAL;

            let positionXinData = Math.floor(newMousePositionX / TOLERANCE);
            let positionYinData = Math.floor(newMousePositionY / TOLERANCE);

            let isPassingTotal = true;

            for (let i = 0; i < shrimpCollision.current.length && i + positionYinData < data.length; i++) {
                for (let j = 0; j < shrimpCollision.current[i].length && j + positionXinData < data[i].length; j++) {
                    let pixelShrimp = shrimpCollision.current[i][j];
                    let pixelMap = data[i + positionYinData][j + positionXinData];

                    if (pixelMap === 1 && pixelShrimp === 1) {
                        isPassingTotal = false;
                    }
                }
            }

            if (!isPassingTotal) {
                currentPosition.current.x = lastPosition.current.x;
                currentPosition.current.y = lastPosition.current.y;

                newMousePositionY = lastPosition.current.y / ratio;
                newMousePositionX = ((currentPosition.current.x - translate.current) / ratio) % WIDTH_INITIAL;

                positionXinData = Math.floor(newMousePositionX / TOLERANCE);
                positionYinData = Math.floor(newMousePositionY / TOLERANCE);

                let isPassingX = true;

                for (let i = 0; i < shrimpCollision.current.length && i + positionYinData < data.length; i++) {
                    for (let j = 0; j < shrimpCollision.current[i].length && j + positionXinData < data[i].length; j++) {
                        let pixelShrimp = shrimpCollision.current[i][j];
                        let pixelMap = data[i + positionYinData][j + positionXinData];

                        if (pixelMap === 1 && pixelShrimp === 1) {
                            isPassingX = false;
                        }
                    }
                }

                if (!isPassingX) {
                    newMousePositionY = currentPosition.current.y / ratio;
                    newMousePositionX = ((lastPosition.current.x - translate.current) / ratio) % WIDTH_INITIAL;

                    positionXinData = Math.floor(newMousePositionX / TOLERANCE);
                    positionYinData = Math.floor(newMousePositionY / TOLERANCE);

                    let isPassingY = true;

                    for (let i = 0; i < shrimpCollision.current.length && i + positionYinData < data.length; i++) {
                        for (let j = 0; j < shrimpCollision.current[i].length && j + positionXinData < data[i].length; j++) {
                            let pixelShrimp = shrimpCollision.current[i][j];
                            let pixelMap = data[i + positionYinData][j + positionXinData];

                            if (pixelMap === 1 && pixelShrimp === 1) {
                                isPassingY = false;
                            }
                        }
                    }

                    if (isPassingY) {
                        currentPosition.current.x = lastPosition.current.x;
                    } else {
                        currentPosition.current.x = lastPosition.current.x;
                        currentPosition.current.y = lastPosition.current.y;
                    }

                } else {
                    currentPosition.current.y = lastPosition.current.y
                }
            }

            if (currentPosition.current.x < -translate.current - shrimpInfos.width) {
                finishGame();
            }

            /*******************/
            /*  FIN COLLISION  */
            /*******************/

            setOrientation(lastPosition.current.y, currentPosition.current.y)

            lastPosition.current.x = currentPosition.current.x;
            lastPosition.current.y = currentPosition.current.y;

            shrimpPosition.current.style = `top: ${currentPosition.current.y}px; left: ${currentPosition.current.x + translate.current}px;`;

            let percentPos = currentPosition.current.y / window.innerHeight;

            /*******************/
            /*  ETAT SHRIMP    */
            /*******************/
            shrimpPosition.current.classList.remove("gameTemperature__shrimp--givree");
            shrimpPosition.current.classList.remove("gameTemperature__shrimp--brulee");
            if (isPassingTotal) {
                if (percentPos < 0.25) {
                    etat.current = "brulee";
                    shrimpPosition.current.classList.add("gameTemperature__shrimp--brulee")
                    if (lastEtat.current !== etat.current) {
                        stop("temperature_cold")
                        play("temperature_hot", true);
                    }
                } else if (percentPos > 0.4) {
                    etat.current = "givree";
                    shrimpPosition.current.classList.add("gameTemperature__shrimp--givree")
                    if (lastEtat.current !== etat.current) {
                        stop("temperature_hot")
                        play("temperature_cold", true);
                    }
                } else {
                    etat.current = "neutre";
                    if (lastEtat.current !== etat.current) {
                        stop("temperature_cold")
                        stop("temperature_hot")
                    }
                }
            } else {
                if (!slapped.current) {
                    let tab = ["temperature_slap", "temperature_slap2"];
                    let index = Math.floor(tab.length * Math.random());

                    play(tab[index], true);
                    slapped.current = true
                    setTimeout(() => slapped.current = false, 1000)
                }

                let type = "";

                if (percentPos < 0.25) {
                    shrimpPosition.current.classList.add("gameTemperature__shrimp--bruleecognee")
                    setTimeout(() => shrimpPosition?.current && shrimpPosition.current.classList.remove("gameTemperature__shrimp--bruleecognee"), 1000);
                    type = "brulee";
                } else if (percentPos > 0.4) {
                    shrimpPosition.current.classList.add("gameTemperature__shrimp--givreecognee")
                    setTimeout(() => shrimpPosition?.current && shrimpPosition.current.classList.remove("gameTemperature__shrimp--givreecognee"), 1000);
                    type = "givree";
                } else {
                    shrimpPosition.current.classList.add("gameTemperature__shrimp--neutrecognee")
                    setTimeout(() => shrimpPosition?.current && shrimpPosition.current.classList.remove("gameTemperature__shrimp--neutrecognee"), 1000);
                    type = "neutre";
                }

                if (canIncrementChoc.current) {
                    canIncrementChoc.current = false;
                    nbChoc.current[type]++;

                    setTimeout(() => canIncrementChoc.current = true, 1000);
                }
            }
            lastEtat.current = etat.current;
            /*******************/
            /*  FIN ETAT       */
            /*******************/

            yMouse.current.style.height = `${100 + ((Math.round((currentPosition.current.y * 100) / (window.innerHeight))) * -1) - 15}%`;
            checkThermometre(percentPos);
        }
    }

    const checkThermometre = (percentPos) => {
        if (percentPos > 0.4) {
            container.current.style.backgroundColor = 'rgb(144, 200, 213)';
        } else if (percentPos < 0.25) {
            container.current.style.backgroundColor = 'rgb(230, 88, 61)';
        } else {
            container.current.style.backgroundColor = 'rgb(236, 189, 154)';
        }
    }

    const debug = () => {
        if (canvas.current) {
            canvas.current.width = window.innerWidth;
            canvas.current.height = window.innerHeight;

            let context = canvas.current.getContext("2d");
            let ratio = canvas.current.height / (HEIGHT_INITIAL / TOLERANCE);

            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data[i].length; j++) {
                    if (data[i][j] === 1) {
                        context.fillStyle = "green";
                        context.fillRect(Math.floor(j * ratio), Math.floor(i * ratio), Math.floor(ratio), Math.floor(ratio));
                    }
                }
            }
        }

        if (canvas2.current && shrimpCollision.current) {
            canvas2.current.width = Math.floor(SCALE * window.innerHeight * 14.58 * 1.642857 / 100);
            canvas2.current.height = Math.floor(SCALE * window.innerHeight * 14.58 / 100);

            let context = canvas2.current.getContext("2d");
            let ratio = canvas.current.height / (HEIGHT_INITIAL / TOLERANCE);

            for (let i = 0; i < shrimpCollision.current.length; i++) {
                for (let j = 0; j < shrimpCollision.current[i].length; j++) {
                    if (shrimpCollision.current[i][j] === 1) {
                        context.fillRect(Math.floor(j * ratio), Math.floor(i * ratio), Math.floor(ratio), Math.floor(ratio));
                    }
                }
            }
        }
    }

    useEffect(() => {
        window.addEventListener("resize", resize);
        resize();

        initShrimpCanvas();

        if (game.current.requestPointerLock) {
            game.current.requestPointerLock();
        }

        let req;

        const step = () => {
            if (running.current) {
                if (translate.current) {
                    if (translate.current <= -container.current.offsetWidth + window.innerWidth + 100) {
                        return
                    }
                }

                scrollLeft();
            }

            checkPosition();

            req = requestAnimationFrame(step);
        }
        req = requestAnimationFrame(step);

        play("temperature_background");

        return () => {
            window.removeEventListener("resize", resize);
            cancelAnimationFrame(req);
            stop();
        }
    }, [])

    const checkPointerLock = () => {
        if (document.pointerLockElement == null && game.current.requestPointerLock) {
            game.current.requestPointerLock();
        }
    }

    const getTabRepeat = () => {
        let tab = [];
        for (let i = 0; i < NB_REPETITION; i++) {
            tab.push(i);
        }
        return tab;
    }

    return (
        <div ref={game} data-update={update} className="game gameTemperature" onMouseMove={e => onMouseMove(e)} onTouchEnd={e => e.preventDefault()} onTouchStart={e => onTouchStart(e)} onTouchMove={e => onTouchMove(e)} onClick={() => checkPointerLock()}>
            <Timer ref={timerRef} duration={DURATION} onFinish={() => finishGame()} onStart={() => running.current = true} />
            <div ref={shrimpPosition} className="gameTemperature__shrimp"><canvas id="canvas2" ref={canvas2}></canvas></div>
            <div ref={container} className='gameTemperature__container' style={{ width: (size * 3.25740740 * NB_REPETITION) + "px" }}>
                <canvas id="canvas1" ref={canvas}></canvas>
                {
                    getTabRepeat().map(index1 => {
                        return (
                            data_volcan.map((volcan, index2) => {
                                return (
                                    <div
                                        key={"volcan" + index1 + index2}
                                        className={"gameTemperature__volcan gameTemperature__volcan--" + volcan.type}
                                        style={{
                                            top: volcan.top + "%",
                                            left: ((volcan.left + index1 * 100) / NB_REPETITION) + "%",
                                            animationDelay: - 2 * Math.random() + "s",
                                            width: (0.1852 * size) + "px",
                                            height: (0.1852 * size) + "px"
                                        }}
                                    >
                                    </div>
                                )
                            })
                        )
                    })
                }
                <div className='gameTemperature__background'></div>
            </div>
            <div className='gameTemperature__thermometer'>
                <img src={require('./assets/thermometer.png')} alt="" />
                <span ref={yMouse} className='gameTemperature__thermometer-level' />
                <span className='gameTemperature__thermometer-zero' />
            </div>
        </div>
    )
};

export default GameTemperature;
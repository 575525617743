import React from 'react';

import GameContainer from '../Components/gameContainer/gameContainer';
import GameHeure from '../Games/gameHeure/gameHeure';
import GameVideo from '../Games/gameHeure/assets/logo_video.mp4';
import finVictoire from '../Games/gameHeure/assets/video_victoire.mp4';
import finDefaite from '../Games/gameHeure/assets/video_defaite.mp4';

const TemplateJeuHeure = () => {
    return (
        <main>
            <GameContainer
                Game={GameHeure}
                gameId={3}
                introVideo={GameVideo}
                introVideoWidth={876}
                introVideoHeight={744}
                introTexte="<span>»</span> Choisissez la bonne heure ! »"
                introBouton="Jouer"
                finVictoireVideo={finVictoire}
                finVictoireTexte="Vive la creuse !​"
                finDefaiteVideo={finDefaite}
                finDefaiteTexte="Creuse encore !​"
                finBouton="Jeu suivant"
                nextStep="/temperature"
            />
        </main>
    )
};

export default TemplateJeuHeure;
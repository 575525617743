import React, { useEffect, useRef, useState } from 'react';
import { useImperativeHandle } from 'react';

const Timer = React.forwardRef(({ duration = 10, durationStart = 4, onFinish = () => { }, onStart = () => { }, message = "" }, ref) => {

    const [timingLeft, setTimingLeft] = useState(duration);
    const timingRef = useRef(timingLeft);
    timingRef.current = timingLeft;

    const [timingStartLeft, setTimingStartLeft] = useState(durationStart);
    const timingStartRef = useRef(timingStartLeft);
    timingStartRef.current = timingStartLeft;

    const onFinishRef = useRef(onFinish);
    onFinishRef.current = onFinish;

    const onStartRef = useRef(onStart);
    onStartRef.current = onStart;

    useImperativeHandle(ref, () => ({
        getRatioScoreLinear() {
            let timingUsed = duration - timingLeft;
            let ratioScoreLinear = timingUsed / duration;
            return ratioScoreLinear;
        },
        getRatioScore() {
            let timingUsed = duration - timingLeft;
            let ratioScore = (-6 / duration * timingUsed) + 7;
            ratioScore = ratioScore < 1 ? 1 : ratioScore > 4 ? 4 : ratioScore;

            return ratioScore;
        }
    }));

    useEffect(() => {
        const interval = setInterval(() => {
            if (timingStartRef.current <= 0) {
                clearInterval(interval);
                onStartRef.current();
            } else {
                setTimingStartLeft(timingStartRef.current - 1);
            }
        }, 1000)

        return () => {
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {
        if (timingStartLeft > 0)
            return

        const interval = setInterval(() => {

            if (timingRef.current <= 0) {
                clearInterval(interval);
                onFinishRef.current();
            } else {
                setTimingLeft(timingRef.current - 1);
            }

        }, 1000)

        return () => {
            clearInterval(interval);
        }

    }, [timingStartLeft])

    return (
        <aside className="timer">
            <div className="timer__horloge">{timingLeft}’</div>
            {
                timingStartLeft > 0 && <div className="timer__warning">
                    <div className="timer__warning__number">
                        {timingStartLeft > 1 ? (timingStartLeft - 1) : "GO"}
                    </div>
                    {
                        message && <p className="timer__warning__message">{message}</p>
                    }
                </div>
            }
        </aside>
    )
});

export default Timer;
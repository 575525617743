import React from 'react';

import GameContainer from '../Components/gameContainer/gameContainer';
import GameLumiere from '../Games/gameLumiere/gameLumiere';
import GameVideo from '../Games/gameLumiere/assets/logo_video.mp4';
import finVideo from '../Games/gameLumiere/assets/video_fin.mp4';

const TemplateJeuLumiere = () => {

    return (
        <main>
            <GameContainer
                Game={GameLumiere}
                gameId={1}
                introVideo={GameVideo}
                introVideoWidth={576}
                introVideoHeight={996}
                introTexte="<span>»</span> Éteignez-moi toutes ces lumières ! »"
                introBouton="Jouer"
                finVictoireVideo={finVideo}
                finVictoireTexte="Ah bah bravo, on n’y voit plus rien.​"
                finDefaiteVideo={finVideo}
                finDefaiteTexte="Ah bah bravo, on n’y voit plus rien.​"
                finBouton="Jeu suivant"
                nextStep="/email"
            >
            </GameContainer>
        </main>
    )
};

export default TemplateJeuLumiere;
import React from 'react';
import { useEffect } from 'react';
import Timer from '../../Components/timer/timer';

const GameTemperature = ({ onFinish }) => {
    const finishGame = () => {
        onFinish(true, 30)
    }

    useEffect(() => {

        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');

        const background = new Image();
        background.src = require('../gameTemperature/assets/land.png');
        background.addEventListener("load", () => {
            canvas.width = background.width;
            canvas.height = background.height;
            ctx.drawImage(background, 0, 0, canvas.width, canvas.height);

            let tolerance = 10;


            let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            let safeArea = []
            for (let j = 0; j < canvas.width; j += tolerance) {

                let hauteurMin = 0;
                let hauteurMax = canvas.height;

                for (let i = 0; i < canvas.height; i++) {
                    let index = 4 * (i * canvas.width + j);

                    if (imgData.data[index + 3] === 0 && hauteurMin === 0) {
                        hauteurMin = i;
                    }
                }

                for (let i = canvas.height - 1; i >= 0; i--) {
                    let index = 4 * (i * canvas.width + j);

                    if (imgData.data[index + 3] === 0 && hauteurMax === canvas.height) {
                        hauteurMax = i;
                    }
                }
                ctx.fillRect(j, hauteurMin, 2, 2);
                safeArea.push({ "x": j, "yMin": hauteurMin, "yMax": hauteurMax });
            }
        });
    }, [])

    return (
        <div className="game gameTemperatureCanvas" >
            {/* <Timer duration={3000} onFinish={() => finishGame()} /> */}
            {/* <button className="bouton" onClick={() => onFinish(true, 30)}>Next</button> */}
            <div className="gameTemperatureCanvas__container">
                <canvas id="canvas" />
            </div>
        </div>
    )

};

export default GameTemperature;
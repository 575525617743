import React, { createContext, useEffect, useRef, useState } from "react";
import Cookie from "../../Tools/Cookie";
import sounds from "./sounds.json";

export const SoundBackgroundContext = createContext()
export const SoundBackgroundProvider = ({ children }) => {

    const sons = useRef(sounds);
    const [paused, setPaused] = useState(false);
    const isPausedRef = useRef(false);
    const context = useRef(null)
    const tabSoundToLoad = useRef([]);

    const setMacroPaused = (isPaused) => {
        setPaused(isPaused);
        isPausedRef.current = isPaused;
    }

    useEffect(() => {

        let isOn = Cookie.getCookie("sound");
        if (isOn !== "true" && isOn !== "false") Cookie.createCookie("sound", "true");
        setMacroPaused(isOn === "true");

        if (sons.current) {
            sons.current.forEach(son => {
                if (!son.loading) {
                    son.loading = true;
                    var request = new XMLHttpRequest();
                    request.open('GET', son.mp3, true);
                    request.responseType = 'arraybuffer';
                    request.onload = function () {
                        son.response = request.response;

                        if (context.current) {
                            context.current.decodeAudioData(request.response, function (buffer) {
                                son.audio = buffer;
                                son.loaded = true
                            }, null);
                        } else {
                            son.response = request.response;
                        }
                    }
                    request.send();
                }
            })
        }
    }, []);

    const init = (callback) => {
        context.current = new AudioContext();

        let total = sons.current.filter(son => son.response).length;
        let current = 0;

        sons.current.filter(son => son.response).forEach(son => {
            context.current.decodeAudioData(son.response, function (buffer) {
                son.audio = buffer;
                son.loaded = true;
                current++;
                if (current === total) callback();
            }, () => {
                current++;
                if (current === total) callback();
            });
        })
    }

    const truePlay = (name, restart = false) => {
        context.current.loaded = true;
        let son = sons.current.filter(son => son.name === name)[0];

        if (son && son.loaded) {
            if (!restart) {
                son.audio.currentTime = 0;
            }

            if (isPausedRef.current) {
                if (son.loop) {
                    son.playing = true;
                }
            } else {

                if (son.source && son.source.playbackRate.value === 0) {
                    son.playing = true;
                    son.source.playbackRate.value = 1;
                } else {
                    son.playing = true;
                    son.source = context.current.createBufferSource();
                    son.source.loop = son.loop;
                    son.source.buffer = son.audio;
                    son.source.onended = () => son.playing = false;
                    son.source.connect(context.current.destination);
                    son.source.start();

                }
            }
        }
    }

    const play = (name, restart = false) => {
        if (!context.current) {
            tabSoundToLoad.current.push(() => truePlay(name, restart = false));
            init(() => {
                tabSoundToLoad.current.forEach(item => item())
                tabSoundToLoad.current = [];
            });
        }
        else if (!context.current.loaded) {
            tabSoundToLoad.current.push(() => truePlay(name, restart = false));
        } else {
            truePlay(name, restart = false)
        }
    }

    const toggle = () => {
        setMacroPaused(!paused);
        paused ? playAll() : pauseAll();
    }

    const playAll = () => {
        Cookie.createCookie("sound", "false");
        sons.current.filter(son => son.playing).forEach(son => play(son.name, true))
    }

    const pauseAll = () => {
        Cookie.createCookie("sound", "true");
        sons.current.forEach(son => {
            if (son.source) son.source.playbackRate.value = 0
        })
    }

    const stopSound = (son) => {
        if (!son.unstoppable && son.source) {
            son.source.playbackRate.value = 0;
            son.audio.currentTime = 0;
            son.playing = false;
        }
    }

    const stop = (name) => {
        if (name) {
            let son = sons.current.filter(son => son.name === name)[0];
            if (son && son.playing) stopSound(son)
        } else {
            tabSoundToLoad.current = [];
            sons.current.forEach(son => stopSound(son))
        }
    }

    return (
        <SoundBackgroundContext.Provider value={{ toggle, paused, stop, play }}>
            {children}
        </SoundBackgroundContext.Provider>
    )
}

import React, { useEffect, useRef, useState, useContext } from 'react';
import lights from "./lights.json";
import Timer from '../../Components/timer/timer';
import { SoundBackgroundContext } from '../../Components/sound/sound-background';

const GameLumiere = ({ onFinish }) => {

    const DURATION = 30;
    const timerRef = useRef();

    const finishGame = (success) => {
        let ratioScore = timerRef.current.getRatioScore();
        let points = ratioScore * 50 * (pointsLeft.current / pointsTotal.current);

        onFinish(success, points)
    }

    const { play, stop } = useContext(SoundBackgroundContext)

    const points = useRef(lights);

    const IMAGE_WIDTH = 2160;
    const IMAGE_HEIGHT = 1080;
    const IMAGE_RATIO = IMAGE_WIDTH / IMAGE_HEIGHT;

    const FOOTER_HEIGHT = 0;

    const [update, nbUpdate] = useState(1);
    const [size, changeSize] = useState([]);

    const [ready, setReady] = useState(false);

    const pointsTotal = useRef(1000);
    const pointsLeft = useRef(0)

    const game = useRef(null);

    const gameLumiere_box = useRef(null);

    const RANDOM_RESTART = 0.0015;

    const checkScreen = useRef(() => { });
    checkScreen.current = () => {
        if (gameLumiere_box.current) {
            let lightsDOM = gameLumiere_box.current.querySelectorAll(".gameLumiere_light");
            lightsDOM.forEach(light => {
                let infos = light.getBoundingClientRect();
                points.current.filter(point => point.id === parseInt(light.dataset.id))[0].inScreen = (infos.x > 10 && infos.y > 10 && infos.x < size[0] - 10 && infos.y < size[1] - 10);
            });
            nbUpdate(update + 1);
        }
    }

    const resize = () => {
        if (game.current) {
            let gameInfos = game.current.getBoundingClientRect();
            changeSize([gameInfos.width, (gameInfos.height - FOOTER_HEIGHT), gameInfos.width / (gameInfos.height - FOOTER_HEIGHT) > IMAGE_RATIO ? "horizontal" : "vertical"]);
        }
    }

    useEffect(() => {
        setTimeout(checkScreen.current, 1);
    }, [size]);

    useEffect(() => {
        window.addEventListener("resize", resize);
        resize();

        points.current.forEach(point => point.on = true);

        let interval = setInterval(() => {
            let isAnyModif = false;
            points.current.filter(point => !point.on).forEach(point => {
                if (Math.random() < RANDOM_RESTART) {
                    point.on = true;
                    isAnyModif = true;
                    checkLight();
                }
            });
            if (isAnyModif) {
                nbUpdate(update => update + 1);
            }
        }, 100);

        play("lumiere_background")

        return () => {
            window.removeEventListener("resize", resize);
            clearInterval(interval);
            stop()
        }
    }, []);

    const checkLight = () => {
        const length = points.current.filter(point => point.on && point.inScreen).length;

        pointsTotal.current = points.current.filter(point => point.inScreen).length;
        pointsLeft.current = pointsTotal.current - length;

        return length;
    }

    const changeId = (id) => {

        if (id === 30) {
            play("lumiere_alien");
        } else {
            let sounds = ["lumiere_flash01", "lumiere_flash02", "lumiere_flash03", "lumiere_lazer01", "lumiere_lazer02", "lumiere_lazer03"];
            let index = Math.floor(Math.random() * sounds.length);
            play(sounds[index]);
        }

        points.current.filter(point => point.id === id).forEach(point => point.on = false);
        const length = checkLight();

        if (length > 0) {
            nbUpdate(update + 1);
        } else {
            finishGame(1);
        }
    }

    const getPos = (point, type) => {
        return point[type === "y" ? "y" : "x"] / (size[2] === "horizontal" ? 2160 : 1080) * size[size[2] === "horizontal" ? "0" : "1"] + "px";
    }

    return (
        <>
            {
                <div ref={game} className="game gameLumiere" data-update={update} onClick={() => play("lumiere_click")}>
                    <Timer ref={timerRef} duration={DURATION} onFinish={() => finishGame(-1)} onStart={() => setReady(true)} />
                    <div
                        ref={gameLumiere_box}
                        className={"gameLumiere_box " + (size[2] === "horizontal" ? "gameLumiere_box--horizontal" : "gameLumiere_box--vertical")}
                        style={{ width: (size[2] === "vertical" ? 2 * size[1] : "") }}
                    >
                        <div className="gameLumiere_scale">
                            <div>
                                <picture className={"gameLumiere_bg " + (size[2] === "horizontal" ? "gameLumiere_bg--horizontal" : "gameLumiere_bg--vertical")}>
                                    <source srcSet={require("./assets/bg.webp")} type="image/webp" style={{ height: (size[2] === "vertical" ? size[1] : "") }} />
                                    <img src={require("./assets/bg.png")} alt="" onLoad={() => checkScreen.current()} style={{ height: (size[2] === "vertical" ? size[1] : "") }} />
                                </picture>

                                <img className={"gameLumiere_lines"} src={require("./assets/lines.png")} alt="" />
                                <img className={"gameLumiere_night"} src={require("./assets/night.png")} alt="" style={{ opacity: pointsLeft.current / pointsTotal.current }} />
                                <img className={"gameLumiere_nuage gameLumiere_nuage--1"} src={require("./assets/nuage.png")} alt="" />
                                <img className={"gameLumiere_nuage gameLumiere_nuage--2"} src={require("./assets/nuage.png")} alt="" />
                                <img className={"gameLumiere_nuage gameLumiere_nuage--3"} src={require("./assets/nuage.png")} alt="" />
                                <img className={"gameLumiere_nuage gameLumiere_nuage--4"} src={require("./assets/nuage.png")} alt="" />
                                <div
                                    className={"gameLumiere_alien"}
                                    style={{
                                        backgroundImage: "url(" + require("./assets/sprite_ze_darkside-anim01.png") + ")",
                                        width: "6%",
                                        height: "11.1%"
                                    }}
                                />
                            </div>

                            <div className={ready ? "gameLumiere_lightBox--ready" : "gameLumiere_lightBox"}>
                                {
                                    points.current.map((point) => {
                                        return (
                                            <button
                                                key={"gameLumiere" + point.id}
                                                data-id={point.id}
                                                className={"gameLumiere_light " + (point.on && point.inScreen ? "" : "gameLumiere_light--hidden")}
                                                onClick={() => changeId(point.id)}
                                                style={{
                                                    backgroundImage: (point.sheet && point.animation && point.rows) ? "url(" + require("" + point.sheet) + ")" : "url(" + require("./assets/lights.png") + ")",
                                                    backgroundPosition: (point.sheet && point.animation && point.rows) ? "0 0" : "-" + getPos(point, "x") + " -" + getPos(point, "y"),
                                                    left: getPos(point, "x"),
                                                    top: getPos(point, "y"),
                                                    width: point.width,
                                                    height: point.height,
                                                    backgroundSize: (point.sheet && point.animation && point.rows) ? "100% " + (point.rows * 100) + "%" : (size[2] === "horizontal" ? "100vw auto" : "auto " + size[1] + "px"),
                                                    animation: (point.sheet && point.animation && point.rows) ? point.animation + " " + (point.rows / 12) + "s steps(" + point.rows + ") infinite" : point.gresillement ? "gresillement_animation 4s linear infinite" : "none",
                                                    animationDelay: point.gresillement ? (Math.random() * 2) + "s" : "0s"
                                                }}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>


                </div>

            }
        </>
    )
};

export default GameLumiere;
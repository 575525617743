import React, { useContext, useEffect } from 'react';
import picto from './assets/picto_son.png'
import { SoundBackgroundContext } from '../sound/sound-background';


const Footer = () => {

    const { toggle, paused } = useContext(SoundBackgroundContext)

    return (
        <footer className="footer">
            <div className="footer_sound">
                <button
                    onClick={() => toggle()}
                    style={{ opacity: paused ? 0.5 : 1 }}
                >
                    <img src={picto} alt="" />
                </button>
            </div>
        </footer>
    )
};

export default Footer;